<template>


<b-row>


  <retirar v-if="openModalRetiro" :opcion-retiro="opcionRetiro"  :key="componentKeyReiniciar"   :user-id="userId" :token-auth="tokenAuth"  />


 

      <b-col
        sm="12"
        md="12"
        lg="4"
        xl="3"
      >

      

          <b-row>
        <b-col sm="12" md="12" lg="12" xl="12" >

          <h4 class="font-weight-bolder" style="text-align: left !important;">
          <feather-icon
                        icon="CheckSquareIcon"
                        size="21"
                        @click="refresh"
                        class="color_icon"
                        />
           Opciones de retiros de dinero
     
        </h4>
        </b-col>

        <b-col cols="12" sm="12" md="12" lg="12" xl="12" >

        <b-card class="card_opciones" block @click="retirar('Banco')">

              <b-row>
                  <b-col cols="2" sm="2" md="2" lg="2" xl="2" align="center" style="display: flex;" >

                    <b-avatar
                    size="40"
                    variant="light-primary"
                    style="margin:auto;">

                    <span class="d-flex align-items-center">
                      <feather-icon
                              icon="HomeIcon"
                              size="25"
                              class="icon_opciones2"
                              />

                    </span>

                    </b-avatar> 




                      </b-col>

                      <b-col cols="10" sm="10" md="10" lg="10" xl="10" >

                            <p class="p1_opciones">Por transferencia bancaria </p>  
                          <p class="p2_opciones">Hacia cualquier banco </p>  


                      </b-col>
          </b-row>

        </b-card>

        </b-col>

       



          

        </b-row>

    



       

      </b-col>



 

        <b-col
        sm="12"
        md="12"
        lg="8"
        xl="9"
      >

      <b-card>

      

              <listado-retiros
            :key="componentKeyListadoRetiros"
            :user-id="userId"
             :token-auth="tokenAuth"
          />


        </b-card>

      
      </b-col>








</b-row>



</template>

<script>
import {
  BButton,BSkeleton,VBPopover, BRow, BCol, BCard,BAlert, BForm, BAvatar
} from 'bootstrap-vue'
import Retirar from './ModalRetirar.vue'
import PincodeInput from 'vue-pincode-input'
import ListadoRetiros from './ListadoRetiros.vue'
import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';
export default {
  components: {
    BSkeleton,
    BButton,
    BAlert,
    BAvatar,
    BRow,
    BCol,
    Retirar,
    BForm,
    BCard,
    PincodeInput,
    ListadoRetiros,
    AutoNumericVue
  },
    directives: {
    'b-popover': VBPopover,
  },
  data() {

    const datos_json = JSON.parse(this.$userGlobal)

 

    return {
      tokenAuth: this.$tokenGlobal,
       userId:datos_json.userId,
       componentKeyListadoRetiros:0,
       componentKeyReiniciar:0,
       pin:"",
        saldoDisponible:0,
        isProfileVerificated:false,
        minRetiro:1,
        comisionRetiro:1,
        isDisabled:false,
        mount:"",
        json_opciones:{},
        openModalRetiro:false,
        opcionRetiro:"",

    }
  },
  computed: {

  },
  watch: {

  },
  activated() {

this.$eventBus.$emit('reiniciarMenu1')
 this.$eventBus.$emit('reiniciarMenu2')


},
  mounted() {



     this.json_opciones={allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",   minimumValue: 0, maximumValue:1000000, modifyValueOnWheel: false };




     
    document.title = 'EQCoop - Retiros de dinero'

        this.$eventBus.$on('reiniciarListadoRetiros', () => {
      this.componentKeyListadoRetiros += 1
    });


            this.$eventBus.$on('reiniciarRetiro', () => {
      this.componentKeyReiniciar += 1;
      this.openModalRetiro = false;
    });







   
  },
  methods: {

     onSubmit(event) {
      event.preventDefault()

     

        this.mount= Number(this.mount).toFixed(2);


      const userId_json = {
        userId: this.userId, pin: this.pin, mount: this.mount, source:this.opcionRetiro
      }



      const user_string = JSON.stringify(userId_json)

      const dataEncripted = this.$encryptBackoffice.encrypt(user_string)
    
      this.isDisabled = true

      document.getElementById('btn_transferir').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Retirando'

      this.$https.post('/banks/withdraw/', { tokenAuth: this.tokenAuth, data: dataEncripted}).then(response => {
      this.isDisabled = false

    
  
        document.getElementById('btn_transferir').innerHTML = 'Retirar dinero';
       // this.$eventBus.$emit('reiniciarListadoInterbilletera');

        if (response.data.code == 200) {

           this.$refs.modalRetirar.hide();

        

          this.$toast.success(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          });

          this.$eventBus.$emit('reiniciarListadoRetiros');




        } else {
          this.isDisabled = false

          this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

          if (response.data.code == 401) {
            
            localStorage.removeItem('userData')



            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          } else if (response.data.code == 503) {
            this.pin = ''
          }else if(response.data.code == 501){
                  this.$swal({
                      title: 'Tu perfil debe estar verificado para realizar esta acción',
                      text: `¿Deseas verificar tu cuenta ahora?`,
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Verificar cuenta',
                      cancelButtonText: 'No',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-secondary ml-1',
                      },
                      buttonsStyling: false,
                    }).then(result => {
                      if (result.value) {

                        this.$router.push({ name: 'perfil', hash: '#verificacion' })

                      }
                    })
                }
        }
      }).catch(error => {
        this.$toast.error("Ocurrió un error inesperado", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })

      

    
    },

     onBlurMount(){



      if(this.mount == null || this.mount == "null" || this.mount ==""){
        this.mount ="";



        }else{

          if(Number(this.mount) < Number(this.minRetiro)){

             this.mount ="";
                  this.$toast.error("La cantidad a retirar debe ser mayor a la cantidad mínima de retiro de $" +this.minRetiro+" USD" , {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                      })



          }else{


            if(Number(this.mount) > Number(this.saldoDisponible)){
              

                  this.mount="";

                   this.$toast.error("La cantidad a retirar debe ser menor a su saldo disponible de $" +this.saldoDisponible+" USD", {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                      })

          


              }

          }

       

          


        }

    },


    retirar(type) {


       this.opcionRetiro=type;
       


           
       this.openModalRetiro = true;

      



    },

  
    refresh() {
      this.$eventBus.$emit('reiniciarRetiro')
    }

    

  },
}
</script>

<style lang="scss">






</style>
